//@flow

import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'gatsby-plugin-intl';

import { Cover } from '../media';
import type { Props as CoverProps } from '../media/Cover';

import {
  Wrapper,
  ChapterNumber,
  ChapterTitle,
  TextWrapper,
  Description,
} from './Chapter.style';

type Props = {
  ...CoverProps,
  number: Number,
  title: String,
  description: String,
  onClick: () => void,
};

const Chapter = ({
  title,
  description,
  number,
  viewed,
  inprogress,
  poster,
  collapsed,
  hidePlay,
  alignRight,
  onClick,
}: Props) => {
  return (
    <Wrapper
      className={classNames({
        collapsed,
      })}
    >
      <Cover
        poster={poster}
        viewed={viewed}
        inprogress={inprogress}
        alignRight={alignRight}
        hidePlay={hidePlay}
        onClick={onClick}
      />
      <TextWrapper>
        <ChapterNumber><FormattedMessage id="program.chapter" /> {number}</ChapterNumber>
        <ChapterTitle>{title}</ChapterTitle>
        <Description>{description}</Description>
      </TextWrapper>
    </Wrapper>
  );
};

Chapter.defaultProps = {

};

export default Chapter;
