import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { navigate, useIntl } from 'gatsby-plugin-intl';

import { ProgressLine } from '../components/media';
import Chapter from '../components/items/Chapter';
import { programs as programsLocales } from '../datas';

import useGlobal from '../store';

import {
  Wrapper,
  Line,
  Aside,
  Main,
} from './Program.style';

const renderPrograms = (chapters) => {
  return chapters.map((chapter, idx) => {
    const { progress, watched } = chapter;
  
    const isLast = chapters.length === idx + 1;

    return (
      <Line
        key={idx}
      >
        <Aside>
          <ProgressLine
            progress={progress}
            className={isLast ? 'no-overflow' : ''}
          />
        </Aside>
        <Main>
          <Chapter
            poster={chapter.cover}
            viewed={watched}
            inprogress={progress > 0 && progress < 1 ? progress : false}
            number={chapter.number}
            title={chapter.title}
            description={chapter.description}
            alignRight
            hidePlay={!watched && !progress}
            onClick={() => navigate(`/app/chapter/${chapter.number}`)}
          />
        </Main>
      </Line>
    );
  });
};

const Program = () => {
  const intl = useIntl();
  const programs = programsLocales[intl.locale];
  const [chaptersState, refreshActivity] = useGlobal(
    state => state.chapters,
    actions => actions.refreshActivity,
  );

  useEffect(() => {
    console.log('Refresh data from api');
    refreshActivity();
  }, []);

  const covers = useStaticQuery(
    graphql`
      query ProgramGetImages($maxWidth: Int) {
        chapter1: file(relativePath: { eq: "video/thumb1.png" }) {
          ...defaultImage
        }
        chapter2: file(relativePath: { eq: "video/thumb3.png" }) {
          ...defaultImage
        }
        chapter3: file(relativePath: { eq: "video/thumb7.png" }) {
          ...defaultImage
        },
        chapter4: file(relativePath: { eq: "video/thumb9.png" }) {
          ...defaultImage
        },
        chapter5: file(relativePath: { eq: "video/thumb15.png" }) {
          ...defaultImage
        },
        chapter6: file(relativePath: { eq: "video/thumb18.png" }) {
          ...defaultImage
        }
      }
    `,
    {
      maxWidth: 420,
    }
  );

  const chapters = programs.map((chapter) => {
    const { episodes } = chapter;
    const chapterState = chaptersState && chaptersState.find(c => c.number === chapter.number);

    const episodeWatched = episodes.reduce((acc, episode) => {
      const episodeState = chapterState && chapterState.episodes.find(e => e.number === episode.number);
      return acc + (episodeState && episodeState.progress);
    }, 0);

    const progress = episodeWatched / episodes.length;
    const watched = progress === 1;

    return {
      ...chapter,
      cover: covers[`chapter${chapter.number}`],
      watched,
      progress,
    }; 
  });

  return (
    <Wrapper>
      {renderPrograms(chapters)}
    </Wrapper>
  );
};

export default Program;
