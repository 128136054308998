//@flow

import React from 'react';

import { Title } from '../../components/typo';
import SEO from '../../components/seo';
import { PrivateRoute } from '../../services/auth';

import ProgramScreen from '../../screens/Program';

const ProgramPage = () => {
  return (
    <>
      <SEO title="Home" />
      <Title t="program.title" d="Your program" center />
      <ProgramScreen />
    </>
  );
};

export default PrivateRoute(ProgramPage);
