import styled from 'styled-components';



export const ChapterNumber = styled.h3`
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  margin: 8px 0;
  padding: 0;

  transition: opacity 200ms;

  & + div {
    margin-top: 10px;
  }
`;

export const ChapterTitle = styled.h3`
  font-weight: bold;
  font-size: 20px;
  margin: 8px 0;
`;

export const Description = styled.p`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: lighter;
  line-height: 1.3em;
  margin: 8px 0;
`;

export const Wrapper = styled.div`
  
`;

export const TextWrapper = styled.div`
  margin: 8px 10px;
`;