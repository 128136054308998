import styled from 'styled-components';

export const Aside = styled.aside`
  flex: 0 0 35px;
`;

export const Main = styled.main`
  flex: 1;
`;

export const Wrapper = styled.main`
  
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -35px;
`;